const guides = [
  {
    title: "Les points à savoir",
    link: "guide1",
  },
  {
    title: "La Délégation",
    link: "guide2",
  },
  {
    title: "Les garanties",
    link: "guide3",
  },
  {
    title: "(Re) Négocier",
    link: "guide4",
  },
];

export default guides;
